import React from 'react'
import { withSiteData } from '../../components/SiteData'
import AppLayout from "../../components/AppLayout";
import PageInfo from '../../components/PageInfo'
import FeatureContentPage from '../../components/FeatureContentPage'
import { SupportEmailAddress } from '../../components/EmailAddresses'
import { LegalCopy } from '../../components/common/Legal'

const LAST_MODIFIED_DATE = (<span>May 1, 2020</span>);
const LAST_CHANGES = (<span>On that date it was updated to reflect a change in the online bookkeeping software that we use.</span>);
// From lawyer: Contact info can be just an email or form
const CONTACT_INFO = (<SupportEmailAddress/>);

export default withSiteData(({ productName }) => (
	<AppLayout>
		<FeatureContentPage
			title={"Privacy Policy"}
		>
			<PageInfo
				title={"Privacy Policy - " + productName}
				description={"We take your privacy seriously. We are committed to handling your team's time entries and other information in the same manner we would expect for our own data."}
			/>

			<LegalCopy>
				<section>
					<p>
						Thank you for using our website. We take your privacy seriously at Moment Software LLC (the owner of this website and software). This Privacy Policy explains how we collect your information and what happens with your information.
					</p>
					<p>
						By using this website, purchasing a subscription, downloading one of our apps, or using this software, you consent to the terms of this Privacy Policy, so take the time to review the terms so you can make an informed choice about whether you will continue in this behavior.
					</p>
					<p>
						This privacy policy was last changed on {LAST_MODIFIED_DATE}. {LAST_CHANGES}
					</p>
				</section>
				<section>
					<h2>What Personal Information Do We Collect?</h2>
					<p>
						We collect your name and email when you create an account as a user or as the owner of a team.
					</p>
					<p>
						We collect your name, email, phone number, team/company name, and address when you purchase a subscription or when you sign up for a free trial subscription.
					</p>
					<p>
						When you purchase a subscription, you use the Stripe payment processing system. Your credit card information is passed directly to Stripe but we only have access to the last four digits of your credit card number.
					</p>
					<p>
						We collect your name, email, and phone number (if provided by you) when you submit customer support requests. We record customer support calls, for training and legal purposes.
					</p>
					<p>
						We collect non-identifiable personal information using "cookies." A cookie is a small data file that a website can transfer to your hard drive through your browser. The cookie is then stored on your computer as an anonymous tag that identifies your computer, but not you. You can set your browser to notify you if cookies are received, or set up your browser to not accept cookies. If you set your browser to block cookies, it may interfere with your ability to use our software.
					</p>
					<p>
						We may collect some other personally identifying information about your use of our website in a non-identifiable format and combine it with other non-identifiable information, to be analyzed only at an aggregate level for marketing purposes.
					</p>
				</section>
				<section>
					<h2>How Do We Use Your Information?</h2>
					<p>
						We use your name and email to contact you about your user account, to contact you about your subscription, to reply to your questions, and to send you occasional marketing announcements and business updates. We use your address to process your transaction. We use your phone number to respond to customer support requests, per your request.
					</p>
					<p>
						We use first-party and third-party cookies to compile aggregate data about site traffic and site interaction so we can optimize our website and create a better user experience for you. We may use cookies via Google Analytics Advertising Features and Facebook Advertising for behavior tracking, remarketing, impression reporting, and interest reporting. Click here to <a href="http://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">opt-out of Google Analytics cookies</a>. If you want to block cookies completely, you can disable them in your browser: <a href="http://support.google.com/accounts/answer/61416?hl=en" target="_blank" rel="noopener noreferrer">Chrome</a>, <a href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox" target="_blank" rel="noopener noreferrer">Firefox</a>, <a href="http://support.apple.com/en-us/HT201265" target="_blank" rel="noopener noreferrer">Safari</a>, or <a href="http://windows.microsoft.com/en-us/windows-vista/Block-or-allow-cookies" target="_blank" rel="noopener noreferrer">Internet Explorer</a>. As stated above, if you set your browser to block cookies, it may interfere with your ability to use our shopping cart, make purchases, or use the membership system section of the website.
					</p>
					<p>
						We do not respond to DNT ("Do Not Track") privacy settings in your website browser. We may use third-party tracking services to provide data to optimize our software.
					</p>
				</section>
				<section>
					<h2>How Do You Unsubscribe?</h2>
					<p>
						If at any time you would like to unsubscribe from receiving future emails, you can opt-out or unsubscribe by clicking on the link in any email we send to you. However, if you opt-out of receiving emails, it may interfere with your ability to receive the full value of your subscription. If you unsubscribe from receiving user invitation messages, users who request invitations on your behalf will be notified of your status.
					</p>
				</section>
				<section>
					<h2>How Do We Protect Your Information?</h2>
					<p>
						We use various security measures to maintain the safety of your personal information, including hosting your data on a password-protected server and/or a password-protected third-party service. Any third-party service we use to host your data will keep your information confidential and will not sell or share your information. We currently use the following third-party service providers: <a href="https://stripe.com/us/privacy" target="_blank" rel="noopener noreferrer">Stripe</a> (payment processing: credit card data, email address, team name, company name, address, order details), <a href="https://www.zendesk.com/company/customers-partners/privacy-policy/" target="_blank" rel="noopener noreferrer">Zendesk</a> (customer support: name, email, phone number if phone support, recordings of phone calls, voicemails), <a href="https://aws.amazon.com/privacy/" target="_blank" rel="noopener noreferrer">Amazon AWS</a> (data storage), and <a href="https://www.xero.com/us/about/legal/privacy/" target="_blank" rel="noopener noreferrer">Xero</a> (bookkeeping).
					</p>
					<p>
						We will also release your information as required by law or if necessary to protect our legal rights, to comply with a judicial proceeding or court order, to prevent threats to others or illegal activities, or to prevent abuse of our software.
					</p>
					<p>
						Circumstances may arise where we may decide to sell, buy, merge, or otherwise reorganize our business in the United States or elsewhere, or where we may decide to share, rent, buy, or sell business assets for reorganizing, developing, or expanding our business. It is our policy to seek appropriate protection for information in these types of transactions. If the company assets or our entire company is acquired, then the lists of personally identifiable information and other contact information will likely be one of the assets acquired.
					</p>
				</section>
				<section>
					<h2>Information Regarding Children</h2>
					<p>
						This website is not directed to people who are under 13 years of age. We do not collect any information from anyone under 13 years of age. Please notify us at {CONTACT_INFO} if you are a parent whose child has provided us with personally identifying information, and we will delete such information from our files.
					</p>
				</section>
				<section>
					<h2>California Residents Privacy Rights</h2>
					<p>
						If you are a California resident, under "Shine the Light law" (Cal Civ Code Section 1798.83) you can request the disclosure of certain personal information that we have disclosed to third parties for marketing purposes in the last year. To make a request, contact us at {CONTACT_INFO}.
					</p>
				</section>
				<section>
					<h2>European Union / Switzerland Residents Privacy Rights</h2>
					<p>
						The European Union enacted the General Data Protection Regulation (GDPR), which gives individuals in the EU/EEA and Switzerland the right to correct, update, review, or delete their personally identifiable data. If you would like to make such a request, please contact us at {CONTACT_INFO}. We are located in the United States and any data you submit will be transferred to the United States. If you are a European Union resident and you are dissatisfied with how we have managed a complaint, you are entitled to contact your local data protection authority.
					</p>
					<p>
						Our data retention policy is that members of the EU have control over us deleting your data; in other words, we will keep your data until you tell us to delete it. For tax purposes, we are required to keep the names and other financial and transactional information about anyone who makes a purchase for at least six years after any transaction. For legal compliance purposes, we keep user and customer files indefinitely, but EU users and customers may request a copy of their file.
					</p>
				</section>
				<section>
					<h2>Questions Regarding This Policy</h2>
					<p>
						If there are any questions regarding this privacy policy, please direct them to: {CONTACT_INFO}
					</p>
				</section>
			</LegalCopy>

		</FeatureContentPage>
	</AppLayout>
));
